import UseResize from '@/features/helpers/UseResize';
import { LoadingOverlay, LoadingOverlayProps } from '@mantine/core';
import { useMemo, useRef } from 'react';

export default function Loading(
  props: Partial<LoadingOverlayProps> &
    Partial<{ overlayOpacity: number; overlayBlur: number; loaderSize: number }>
) {
  const {
    visible = true,
    overlayOpacity = 0,
    overlayBlur = 1,
    overlayProps = {},
    loaderProps = {},
    loaderSize,
    ...rest
  } = props;
  const ref = useRef(null);
  const size = UseResize(ref, true);

  const width = useMemo(() => {
    const { width = 300 } = size;
    const length = (width * 12) / 100;
    return `${length}px`;
  }, [size]);

  return (
    <LoadingOverlay
      className="loading"
      ref={ref}
      ta="center"
      loaderProps={{
        ...loaderProps,
        children: <img src="/logo_small_black_animated.svg" width={loaderSize ?? width} />,
      }}
      overlayProps={{ ...overlayProps, backgroundOpacity: overlayOpacity, blur: overlayBlur }}
      visible={visible}
      {...rest}
    />
  );
}
