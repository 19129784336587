import { PresetCriterias, ScreenerType } from '@/features/api/screener/screenerTypes';
import { tableSortsToCurrentSort } from '@/features/helpers/sortConverter';
import { colsToIgnoreToVisibilityState } from '@/features/helpers/visibilityConverter';
import {
  buildingHeaders,
  defaultColOrder,
  dwellingHeaders,
  fundHeaders,
  lotHeaders,
  transactionHeaders,
} from '@/pages/screener/helper';
import {
  ColumnDef,
  ColumnOrderState,
  ColumnPinningState,
  ColumnSizingState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import DefaultCell from '../Table4/Cells/DefaultCell';
import Header from '../Table4/Cells/Header';
import HeaderSelect from '../Table4/Cells/HeaderSelect';
import RowSelect from '../Table4/Cells/RowSelect';
import { CColumnDef } from '../Table4/helpers/types';
import useTableCore from '../Table4/hooks/useTableCore';
import { defaultColToIgnore } from './helper';
import { ScreenerTransactionsReturn, TScreenerResult } from './returnTypes';

function getHeader(type: ScreenerType) {
  switch (type) {
    case 'buildings':
      return buildingHeaders;
    case 'transactions':
      return transactionHeaders;
    case 'dwellings':
      return dwellingHeaders;
    case 'lots':
      return lotHeaders;
    case 'funds':
      return fundHeaders;
  }
}

type CustomSize = {
  [x: string]: { min?: number; default: number };
};

const customSize: CustomSize = {
  ewid: { min: 65, default: 65 },
  date: { min: 65, default: 65 },
  zip: { min: 110, default: 110 },
  launch_date: { min: 110, default: 110 },
};

const disabledSort = [
  'potential_net_rent_monthly',
  'potential_charges_monthly',
  'real_net_rent_monthly',
  'real_charges_monthly',
];

export const defaultFirstCols: Record<ScreenerType, string[]> = {
  lots: ['egrid'],
  buildings: ['street_short'],
  funds: ['short_name'],
  dwellings: ['street_short', 'ewid'],
  transactions: ['street_short', 'date'],
};

export default function useScreenerTableConfiguration({
  currentSelections,
  data,
}: {
  currentSelections: PresetCriterias;
  data?: TScreenerResult[];
}) {
  const firstColumns = useMemo(() => {
    const type = currentSelections.type;
    if (!type) return [''];
    return defaultFirstCols[type];
  }, [currentSelections.type]);

  const headers = useMemo(
    () => (currentSelections.type ? getHeader(currentSelections.type) : []),
    [currentSelections.type]
  );

  const defaultColumnOrder = useMemo(
    () => {
      return currentSelections.type ? defaultColOrder[currentSelections.type] : [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headers, firstColumns, currentSelections.columnsToIgnore]
  );

  const defaultColumnPinning = useMemo(
    () => ({ left: ['select', ...firstColumns], right: [] }),
    [firstColumns]
  );

  const defaultColumnVisibility = useMemo(() => {
    if (!currentSelections.type) return {};
    else return colsToIgnoreToVisibilityState(defaultColToIgnore[currentSelections.type]);
  }, [currentSelections.type]);

  const columns = useMemo<CColumnDef<TScreenerResult>>(() => {
    const type = currentSelections.type;
    if (!type) return [];

    const selectionColumn: ColumnDef<TScreenerResult> = {
      cell: (ctx) => (
        <div className="cell" key={`cell-${ctx.row.id}-${ctx.column.id}`}>
          <RowSelect {...ctx} />
        </div>
      ),
      header: (ctx) => (
        <div key="header-select" className="header-with-options">
          <HeaderSelect key="header-select" {...ctx} />
        </div>
      ),
      id: 'select',
      size: 32,
      enablePinning: false,
    };
    return [
      selectionColumn,
      ...headers.map<ColumnDef<TScreenerResult>>((header) => {
        const { label, accessKey = label } = header;
        return {
          header: (ctx) => <Header key={`header-${accessKey}`} {...ctx} {...header} />,
          accessorKey: accessKey,
          id: accessKey,
          size: customSize[accessKey]?.default || 200, // 200px is what Design team proposed as default value
          minSize: customSize[accessKey]?.min || 150, // They proposed 100, but it doesn't sit well with the dis/appearance of buttons
          enablePinning: firstColumns.includes(accessKey) ? false : undefined,
          enableHiding: firstColumns.includes(accessKey) ? false : undefined,
          enableResizing: true,
          enableSorting: !disabledSort.includes(accessKey),
          cell: (ctx) => (
            <DefaultCell
              {...ctx}
              enableOrder={true}
              noTranslation={header.label === 'bloomberg_ticker' ? true : false}
            />
          ),
        };
      }),
    ];
  }, [currentSelections.type, headers, firstColumns]);

  const columnVisibility = useMemo(() => {
    const columnVisibility = colsToIgnoreToVisibilityState(currentSelections.columnsToIgnore);
    return columnVisibility;
  }, [currentSelections.columnsToIgnore]);

  const sorting = useMemo<SortingState>(() => {
    return tableSortsToCurrentSort(currentSelections.sort) || [];
  }, [currentSelections.sort]);

  const core = useTableCore({
    id: 'screener-result-table',
    data: data || [],
    columns: columns,
    options: {
      manualPagination: true,
      manualSorting: true,
      // Table can multisort, but it was voted that UX needs some update - Put true to try
      enableMultiSort: false,
      // If multisort is set, only up to 3 column can be sorted
      maxMultiSortColCount: 3,
      enableColumnPinning: true,
      getRowId(original, index) {
        if (currentSelections.type === 'transactions')
          return `${(original as ScreenerTransactionsReturn).building_id || index}`;
        return `${original.id || index}`;
      },
      enableStickyRowPinning: false,
    },
    initialState: {
      columnPinning: Object.values(currentSelections.column_pinning || {}).length
        ? currentSelections.column_pinning
        : defaultColumnPinning,
      columnVisibility,
      sorting,
      columnSizing: currentSelections.sizing || {},
      columnOrder: currentSelections.order
        ? [
            ...currentSelections.order.filter(
              (column) =>
                ![
                  'id',
                  'select',
                  ...firstColumns,
                  // If A column was removed from table by visibility
                  // Drag n Drop will have weird behavior
                  ...(currentSelections.columnsToIgnore || []),
                ].includes(column) &&
                // If a column was added by mistake, a typo or was removed on our side: ditch it
                columns.map((col) => col.id).includes(column)
            ),
            // Add forgotten or newly added columns
            ...columns
              .map((col, index) => col.id || `${index}`)
              .filter((col) => {
                return (
                  // Column must not appear in order
                  !currentSelections.order?.find((orderedCol) => col === orderedCol) &&
                  // Neither should it appear in ignored columns
                  !currentSelections.columnsToIgnore?.find((ignoredCol) => col === ignoredCol)
                );
              }),
          ]
        : defaultColumnOrder,
    },
  });

  const table = core[0];
  const state = table.getState();

  const reset = useCallback(
    (configuration?: {
      sort?: SortingState;
      columnSizing?: ColumnSizingState;
      columnPinning?: ColumnPinningState;
      order?: ColumnOrderState;
      columnVisibility?: VisibilityState;
    }) => {
      const {
        columnSizing = {},
        sort = [],
        order = defaultColumnOrder,
        columnPinning = defaultColumnPinning,
        columnVisibility = defaultColumnVisibility,
      } = configuration || {};

      table.setColumnVisibility(columnVisibility);
      table.setColumnPinning(columnPinning);
      table.setColumnOrder(order);
      table.setSorting(sort);
      if (columnSizing) table.setColumnSizing(columnSizing);
      else table.resetColumnSizing(true);
    },
    [defaultColumnOrder, defaultColumnPinning, defaultColumnVisibility, table]
  );

  return {
    firstColumns,
    state,
    headers,
    defaultColumnOrder,
    defaultColumnPinning,
    defaultColumnVisibility,
    customSize,
    disabledSort,
    core,
    reset,
  };
}
