import {
  de_default
} from "./chunk-MN23ARWL.mjs";
import {
  en_default
} from "./chunk-RHBWZCUQ.mjs";
import {
  fr_default
} from "./chunk-KF2CTZQV.mjs";

// src/frontend/index.ts
var frontend_default = { en: en_default, fr: fr_default, de: de_default };

export {
  frontend_default
};
